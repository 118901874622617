import styled from '@emotion/styled';
import {
  BANNER_OPACITY,
  BODY_COLOR_OPACITY,
} from '@/components/DesignSystem/ModernTheme/utils/common';
export const HeroBannerVideoBackground = styled.div`
  background: ${(props) =>
    !props?.propsBackground?.content[0]?.url && `none, ${props?.themeBackground}`};
  background-size: cover;
  background-position: center;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0; /* Set the z-index behind other content */
  }

  /* Add a linear gradient overlay */
  &::after {
    content: '';
    background: ${(props) => props?.themeBackground};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${BANNER_OPACITY};
    z-index: 1;
  }
  @media screen and (min-width: 768px) {
    &.modern_theme_hero_banner3 {
      & > video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; /* Scale the video while maintaining its aspect ratio */
      }
    }
  }
  @media screen and (max-width: 767px) {
    & > video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; /* Scale the video while maintaining its aspect ratio */
    }
  }
  & > video {
    position: absolute;
    height: 100%;
  }
  & > img {
    position: absolute;
    height: 100%;
    object-fit: fill;
    width: 100%;
  }
`;

export const HeroBannerParent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &.bg_type_solid {
    background: ${(props) => props?.themeBackground};
  }
`;
export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 3;
  @media screen and (min-width: 768px) {
    gap: 2.625rem;
    padding: 6rem 8.25rem;
  }
  @media screen and (max-width: 767px) {
    gap: 1.5rem;
    padding: 2rem 1.5rem;
  }
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  & > h1 {
    width: 100%;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
    overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
    color: ${(props) => props?.textStyles?.heading?.color};
    font-family: ${(props) => props?.textStyles?.heading?.fontFamily || 'Arial'};
    text-align: ${(props) => props?.textStyles?.heading?.textAlign || 'center'};
  }
  & > p {
    width: 100%;
    font-style: normal;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    opacity: ${BODY_COLOR_OPACITY};
    color: ${(props) => props?.textStyles?.body?.color};
    text-align: ${(props) => props?.textStyles?.body?.textAlign || 'center'};
    font-family: ${(props) => props?.textStyles?.body?.fontFamily || 'Arial'};
    overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
    overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
  }
  @media screen and (min-width: 768px) {
    gap: 1.5rem;
    & > h1 {
      font-size: ${(props) => props?.textStyles?.heading?.fontSize || '5rem'};
      font-weight: ${(props) => props?.textStyles?.heading?.fontWeight || '500'};
      line-height: 5rem;
    }
    & > p {
      font-size: ${(props) => props?.textStyles?.body?.fontSize || '1.5rem'};
      font-weight: ${(props) => props?.textStyles?.body?.fontWeight || '400'};
      line-height: 1.75rem;
    }
  }
  @media screen and (max-width: 767px) {
    gap: 0.75rem;
    & > h1 {
      font-size: 2rem;
      font-weight: 700;
      line-height: normal;
    }
    & > p {
      font-size: 0.875rem;
      font-weight: 700;
      line-height: normal;
    }
  }
`;
export const BottomSection = styled.div`
  display: flex;
  width: 100%;
  align-self: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  flex-wrap: wrap;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    button {
      width: 100%;
    }
  }
`;
